<template>
  <div class="app-container">
    <el-form ref="data" :rules="correctRules" :model="data" label-width="80px">
      <el-form-item label="附件:">
        {{ data.file ? data.file.name : "暂未上传" }}
        <el-button
          v-if="data.file"
          type="primary"
          size="mini"
          @click="download(data.file.url, data.file.name)"
        >
          下载
        </el-button>
      </el-form-item>
      <template> </template>
      <el-form-item label="评分:" prop="score">
        <span v-if="type == 2 && data.score">
          {{ data.score }}
        </span>
        <el-input
          v-else-if="type == 1"
          type="text"
          v-model="data.score"
          clearable
          style="width: 400px"
        />
      </el-form-item>

      <el-form-item label="批注:" prop="annotation">
        <span v-if="type == 2 && data.score">
          {{ data.annotation }}
        </span>
        <el-input
          v-else-if="type == 1"
          type="textarea"
          v-model="data.annotation"
          clearable
          :rows="5"
          style="width: 400px"
        />
      </el-form-item>
      <el-form-item label="作业描述:">
        {{ data.content || "-" }}
      </el-form-item>
      <el-form-item label="图片:" v-if="data.images && data.images.length > 0">
        <img
          v-for="(img, index) in data.images"
          :key="index"
          :src="img"
          alt=""
          style="width: 400px; margin-right: 10px"
        />
      </el-form-item>
      <el-form-item>
        <el-button @click="$router.go(-1)">返回</el-button>
        <el-button
          type="primary"
          @click="saveData"
          :loading="btnLoading"
          v-if="type == 1"
        >
          评分
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>
<script>
import request from "@/utils/request";
export default {
  data() {
    return {
      btnLoading: false,
      data: null,
      tyep: null,
      correctRules: {
        score: [{ required: true, message: "评分不能为空", trigger: "change" }],
        annotation: [
          { required: true, message: "批注不能为空", trigger: "change" },
        ],
      },
    };
  },
  created() {
    this.data = JSON.parse(this.$route.query.data); //字符串转对象
    this.type = this.$route.query.type;
    console.log(this.type);
    // console.log(this.data);
  },
  mounted() {},
  methods: {
    saveData() {
      this.$refs["data"].validate((valid) => {
        if (valid) {
          this.btnLoading = true;
          request({
            url: "/api/teacherend/schedule/homeworkCorrecting",
            method: "post",
            data: {
              id: this.data.id,
              score: this.data.score,
              annotation: this.data.annotation,
              file: this.data.file,
            },
          })
            .then((response) => {
              this.btnLoading = false;
              this.$message({
                type: "success",
                message: "操作成功",
              });
              this.$router.go(-1);
            })
            .catch((err) => {
              this.btnLoading = false;
            });
        }
      });
    },
    judgeType(name) {
      let result = "";
      let suffix = "";
      try {
        var flieArr = name.split(".");
        suffix = flieArr[flieArr.length - 1];
      } catch (err) {
        suffix = "";
      }
      let imglist = ["png", "jpg", "jpeg", "bmp", "gif"];
      result = imglist.some(function (item) {
        return item == suffix;
      });
      return result;
    },
    getUrlBase64(url) {
      return new Promise((resolve) => {
        let canvas = document.createElement("canvas");
        let ctx = canvas.getContext("2d");
        let img = new Image();
        img.crossOrigin = "Anonymous"; //允许跨域
        img.src = url;
        img.onload = function () {
          canvas.height = 300;
          canvas.width = 300;
          ctx.drawImage(img, 0, 0, 300, 300);
          let dataURL = canvas.toDataURL("image/png");
          canvas = null;
          resolve(dataURL);
        };
      });
    },
    download(item, name) {
      let res = this.judgeType(item);
      if (res) {
        this.getUrlBase64(item).then((base64) => {
          let link = document.createElement("a");
          link.href = base64;
          link.download = item.name || name;
          link.click();
        });
      } else {
        let routeUrl = item.replace(/http:/, "https:");
        window.open(routeUrl, "_parent");
      }
    },
  },
};
</script>
<style lang="scss" scoped>
</style>